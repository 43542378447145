<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <button type="button" color="primary" @click="dismiss()">
                        <img src="../../assets/icons/close.svg" />
                    </button>
                </ion-buttons>

                <ion-title>
                    {{ $t('Your cart') }}
                </ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">

            <p class="alert alert-warning" v-if="!store.state['store'].supportPickup && !store.state['store'].supportDelivery">
                {{ $t('Delivery option not available for now! Please contact store owner.') }}
            </p>

            <div class="no-data" v-if="store.state['cartLength'] == 0">

                <IonIcon :icon="cartOutline"></IonIcon>

                <h5>{{ $t('Your cart is empty') }}</h5>
 
                <p>{{ $t('You can manage your shopping cart here') }}</p>

                <button @click="dismiss()" class="btn">
                    {{ $t('Start Shopping') }}
                </button>

            </div>

            <cart-item :errors="props.errors[index]" :item="item" :cart_item_id="index" 
                v-for="(item, index) in props.items" :key="index"></cart-item>

            <!-- store.state['cart'] -->    
        </ion-content>

        <ion-footer v-if="store.state['cartLength'] > 0 && (store.state['store'].supportPickup || store.state['store'].supportDelivery)">

            <div class="alert alert-warning" v-if="!store.state['store'].supportPickup 
                && store.state['store'].supportDelivery && !store.getters.isStoreOpen">
                
                <template v-if="store.getters.storeReopenAt">
                    {{ $t('Reopen at {time}', { time: store.getters.storeReopenAt }) }}
                </template>
                <template v-if="!store.getters.storeReopenAt">
                    {{ $t('Close now') }}
                </template>
            </div>

            <ion-toolbar> 

                <button class="add-to-cart" @click="goToCheckout()" 
                    :disabled="props.validatingCart || props.haveError || (!store.state['store'].supportPickup && !store.getters.isStoreOpen)">
                    {{ $t('Checkout') }}
                    <span class="sep">·</span>
                    <span class="txt-total"> {{ toPrice(store.state['cartTotal']) }}</span>
                </button>
                
                <button class="btn-shopping" @click="goToHome()">
                    {{ $t('Continue Shopping') }}
                </button> 
                
            </ion-toolbar>
        </ion-footer>
    </ion-page>    
</template>
<script setup lang="ts"> 
import { IonButtons, IonFooter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, IonIcon, onIonViewDidEnter, onIonViewWillEnter } from '@ionic/vue';
import { cartOutline } from 'ionicons/icons';
import CartItem from '@/components/CartItem.vue';
import store from '@/store';
import { toPrice } from '@/pipes/price';
import router from '@/router';
import { trackView } from '@/services/AnalyticsService';
import { validateCart } from '@/services/OrderService';
import { reactive, watch } from 'vue';

//todo: copy checkout success/ failed etc pages from old repo 

/*store.subscribe((mutation, {
    cartLength
}) => {
   if (props.lastCheckAt != store.state['cartTotal']) 
    validateCartAndSetErrors();
});*/

const props = reactive({
  errors: [],
  haveError: false,
  validatingCart: false,
  items: {}
});

watch(() => store.state['cartLength'], async () => {
    validateCartAndSetErrors();
}, {
    flush: "post"
});

/*
watch(() => store.state['cart'], async () =>
{
   console.log('updated');
}, { deep: true });*/
 
//for popup 
validateCartAndSetErrors();
trackView('cart-page');

//for page 

/*onIonViewDidEnter(() => {
    validateCartAndSetErrors();
    trackView('cart-page');
});*/

function validateCartAndSetErrors() {

    let params = [];
 
    for(let i of Object.keys(store.state['cart'])) { 
  
        params.push({
            item_uuid: store.state['cart'][i].item_uuid,
            item_variant_uuid: store.state['cart'][i].variant? 
                store.state['cart'][i].variant.item_variant_uuid: null,
            qty: store.state['cart'][i].qty,
            extraOptions: store.state['cart'][i].extraOptions,
            cart_index: i
        }) 
    }
   
    if (params.length > 0) {
       
        props.validatingCart = true;

        validateCart(params).then(res => {
            
            props.errors = res.errors;
            props.haveError = Object.keys(res.errors).length > 0;
  
            props.items = {};

            let qtyTotal = 0;
            let total = 0; 

            for (let key of Object.keys(res.items)) {
                 
                //const item_price = getItemPrice(res.items[key], store.state['cart'][key].extraOptions) ;

                props.items[key] = Object.assign(res.items[key], {
                    qty: store.state['cart'][key].qty,
                    
                    //extraOptions: res.items[key].extraOptions,
                    //item_price: res.items[key].item_price,
                    //variant: res.items[key].variant
                });
 
                qtyTotal += store.state['cart'][key].qty;
                total += store.state['cart'][key].qty * res.items[key].item_price;
            }
    
            store.commit('validateCart', {
                total,
                qtyTotal,
                items: props.items
            });
            
            //todo: same changes in checkout to display latest data for items 

        }).finally(() => {
            props.validatingCart = false;
        });
    }
}


function getItemPrice(item, extraOptions = null) {

    console.log("item.item_price", item.item_price)
    let price = item.item_type == 1 ? parseFloat(item.item_price) :
        parseFloat(item.variant.price);

    if (extraOptions) {
        for (const i in extraOptions) {

            for (const j in extraOptions[i]) {
                price += parseFloat(extraOptions[i][j].extra_option_price);
            }
        }
    }

    return price;    
}

function goToHome() {
   
   modalController.getTop().then(o => {
       if(o) {
           modalController.dismiss({
               page: "home"
           });
       } else {
           router.push("/home");
       }
   }); 
}

function goToCheckout() {
   
    modalController.getTop().then(o => {
        if(o) {
            modalController.dismiss({
                page: "confirm"
            });
        } else {
            router.push("/confirm");
        }
    }); 
}

function dismiss() {
    modalController.getTop().then(o => {
        if(o) {
            modalController.dismiss();
        //} else if(window.history.length > 0) {
        //    router.back();
        } else {
            router.push('/');
        }
    });
}

</script>
<style lang="scss" scoped>
ion-footer {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
 
    .alert {
        width: 100%;
    }

    .add-to-cart {
        background: var(--ion-color-primary, #453E9A);
        color: var(--ion-color-primary-contrast, #FFF);
    }

    .btn-shopping {
        background: var(--ion-color-secondary, #453E9A);
        color: var(--ion-color-secondary-contrast, #FFF);
    }

    .btn-shopping,
    .add-to-cart {
        padding: 8px 16px; 
        gap: 8px; 
        border-radius: 16px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        height: 56px; 
        margin: 8px 16px;
        width: calc(100% - 32px);
        text-align: center;

        .txt-total {
            color: var(--ion-shades-primary-p-2, #E8E5FF);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
        }

        .sep {
            margin: 0 4px;
        }
    }
}

</style>